import axios from 'axios'
import { Notify } from 'vant'
import md5 from 'js-md5'

/**
 * 第二种请求接口的方法 --> @toUpdate
 * （暂定：因后台接口从c# 过渡到 java 所以接口暂时有2个IP，先写多一个request.js区分，后期再调整）
 */
let BASE_URL = '' // 请求ip（前缀）
if (process.env.NODE_ENV === 'production') {
  BASE_URL = window.VUE_APP_BASE_API
} else {
  // BASE_URL = process.env.VUE_APP_BASE_API
  window.is_param_cipher = 1
  BASE_URL = [
    //   "https://192.168.1.1:8080/v8api",
    //   "https://192.168.1.1:8080/v8api",
    //   "https://192.168.1.1:8080/v8api",
    // "https://v8.hyclound.cn:8080/v8api",
    'https://v8dev.hyclound.cn:8080/v8api'
    //   "https://192.168.1.1:8080/v8api",
    //   "https://192.168.1.1:8080/v8api",
    //   "https://192.168.1.1:8080/v8api",
    //   "https://192.168.1.1:8080/v8api"
  ]
}

window.BASE_URL = BASE_URL
// BASE_URL = "http://119.145.167.150:801/v8api";

// 测试域名是否能访问
// 使用原生XMLHttpRequest 用setTimeout 设置超时时间（ps：axios 的 timeout 在生产环境失效）
async function checkBaseUrl(u) {
  return new Promise((resolve, reject) => {
    var request = new XMLHttpRequest()
    var timeout = false //是否超时
    var timer = setTimeout(function() {
      timeout = true
      request.abort() //请求中止
    }, 1000)
    request.timeout = 5000
    request.open('GET', u + '/api/login/userLogOut', true)
    request.onreadystatechange = function() {
      if (request.readyState !== 4) reject(false) //忽略未完成的请求
      if (timeout) reject(false) //忽略中止请求
      clearTimeout(timer) //取消等待的超时
      if (request.status === 200) {
        BASE_URL = u
        window.VUE_APP_BASE_API = BASE_URL
        resolve(u)
      } else {
        reject(false)
      }
    }
    request.send(null)
  })
}
function bulidPlaintext(config) {
  let t = '',
    obj = {},
    isformdata = false
  // console.log(config)

  if (config.method == 'get' && config.params) {
    for (const key in config.params) {
      if (config.params.hasOwnProperty(key)) {
        if (
          config.params[key] ||
          config.params[key] == 0 ||
          config.params[key] == false
        )
          obj[key] = config.params[key].toString()
      }
    }
  } else if (config.method == 'post') {
    if (config.data instanceof FormData) {
      isformdata = true
      // FormData 类型传参
      config.data.forEach((value, key) => {
        // 排除file类型参数
        if (toString.call(value) != '[object File]') obj[key] = value.toString()
      })
    } else {
      // json 类型传参
      obj = config.data
    }
  }
  // console.log(obj, Object.prototype.toString.call(obj))
  if (
    Object.prototype.toString.call(obj) == '[object Object]' ||
    Object.prototype.toString.call(obj) == '[object Date]' ||
    Object.prototype.toString.call(obj) == '[object Array]'
  )
    t = JSON.stringify(obj)
  if (t)
    t = t
      .replace(/\\\\\\\\t/g, '')
      .replace(/\\\\\\t/g, '')
      .replace(/\\\\t/g, '')
      .replace(/\\t/g, '')
      .replace(/\t/g, '')
      .replace(/\\\\\\\\r/g, '')
      .replace(/\\\\\\r/g, '')
      .replace(/\\\\r/g, '')
      .replace(/\\r/g, '')
      .replace(/\r/g, '')
      .replace(/\\\\\\\\n/g, '')
      .replace(/\\\\\\n/g, '')
      .replace(/\\\\n/g, '')
      .replace(/\\n/g, '')
      .replace(/\n/g, '')
  // if (isformdata)
  t = t.replace(/\\/g, '')
  if (t == '{}') t = ''
  // console.log(t)
  return t
}
/**
 * 二次封装 axios
 * params {_p | baseurl | showErrorMsg} 请求参数 | ip地址（前缀） | 自动报错
 */
export default (_p = {}, baseurl = '', showErrorMsg = true) => {
  return new Promise((resolve, reject) => {
    // 设置默认的请求参数
    const _d = {
      method: 'get',
      // url: '/api/file/UpLoadFIle',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      params: {} // !!!=== 注意: get请求参数用params | post请求参数用data ===!!!
    }
    let localurl = true // 是否后台接口
    // 动态配置baseurl
    if (!baseurl) {
      baseurl = BASE_URL
    } else {
      localurl = false
    }
    // 设置默认post请求的headers
    if (_p.method === 'post') {
      _d.headers = { 'Content-Type': 'application/json;charset=UTF-8' }

      // form-data 请求头需 特殊转换格式
      const _h = _p.headers
      if (_h) {
        const _ct = _h['Content-Type'] || ''
        if (_ct && _ct.includes('multipart/form-data')) {
          const formFile = new FormData()
          for (const k in _p.data) {
            formFile.append(k, _p.data[k])
          }
          _p.data = formFile
        }
      }
    }
    // 动态配置url
    const url = _p.url
    _p.url = baseurl + _p.url // 加上前缀
    // 合并参数
    const params = Object.assign(_d, _p)

    // 在拦截器上无法获取formdata，所以改在这个位置
    // 20230515 加密传参 cipherparam   param_plaintext param_ciphertext_befroe
    if (window.is_param_cipher) {
      let param_plaintext = bulidPlaintext(params)
      let wxToken = window.sessionStorage.getItem('token')
      // 防止pc端缓存污染
      // let token = JSON.parse(window.sessionStorage.getItem('userToken'))
      // let username = JSON.parse(
      //   window.sessionStorage.getItem('relusername') ||
      //     window.sessionStorage.getItem('username')
      // )
      let param_ciphertext_befroe =
        '_v8_aes_before' +
        // 防止pc端缓存污染
        // (token && username ? window.encodeURIComponent(username) + token : '') +
        // (params.headers.v8apitoken || '') +
        (wxToken || '') +
        '39be0c1ae4944b22b6ed62175192cd34' +
        (param_plaintext || '') +
        '_v8_ciper_key_after'

      let cipherparam = md5(param_ciphertext_befroe)
      // if (process.env.NODE_ENV != 'production') {
      //   console.log(url, cipherparam, param_ciphertext_befroe)
      // }
      const dateLength = cipherparam.length
      if (dateLength > 32)
        cipherparam = cipherparam.substring(dateLength - 32, dateLength)
      params.headers.cipherparam = cipherparam
    }

    // axios请求拦截器
    axios.interceptors.request.use(
      async config => {
        // console.log(config, url, "axios.interceptors.request");
        // 接口包含多地址【内外网或者多镜像地址】：数组类型，按顺序测试地址是否能正常访问
        if (localurl) {
          //window.localStorage.getItem("accessToken") 获取token的value
          let token = window.sessionStorage.getItem('token')

          if (token) {
            //将token放到请求头发送给服务器,将tokenkey放在请求头中
            config.headers.wxapitoken = token
          }

          // 如果是后台接口
          // !!!==============================
          const BASE_URL_TYPE = typeof BASE_URL
          if (BASE_URL_TYPE === 'object') {
            // BASE_URL 后台地址是数组，循环数组直到第一个地址可以正常访问
            const g = JSON.parse(JSON.stringify(BASE_URL))
            for (let index = 0; index < g.length; index++) {
              try {
                const u = g[index]
                // BASE_URL = u;

                // 尝试访问地址
                const uu = await checkBaseUrl(u)
              } catch (err) {
                // console.log(err);
              }
            }
            config.url = BASE_URL + url
          }
        }
        // !!!==============================
        return config
      },
      error => {
        return Promise.error(error)
      }
    )

    return axios(params)
      .then(res => {
        let response = {}
        if (Array.isArray(res.data) && res.data.length === 1) {
          // 有时返回数组对象[{}]格式
          response = res.data[0]
        } else {
          // 有时返回对象 || 字符串
          response = res.data ? res.data : res
        }

        const api = params.url.split('/').pop()
        // 个别接口需要特殊处理
        if (api === 'saveImportExcelData') {
          // 导入：返回[{}, {},...]
          response = res.data
        }
        // // 测试专用,用完请删 ( 打印响应数据,方便开发查看 )
        //   console.warn(`${params.url.split('/').pop()}=`, res, response)

        // 数据处理
        if (Object.prototype.toString.call(response) === '[object Object]') {
          if (
            response.iserror === '0' ||
            response.iserror === undefined ||
            !showErrorMsg
          ) {
            resolve(response)
          } else {
            Notify({
              type: 'danger',
              message: response.errormsg
                ? JSON.stringify(response.errormsg)
                : '数据处理异常，请及时联系管理员！'
            })
            reject(response)
          }
        } else if (
          Object.prototype.toString.call(response) === '[object Array]'
        ) {
          resolve(response)
        } else if (typeof response === 'string') {
          resolve(response)
        } else {
          resolve(response)
        }
      })
      .catch(err => {
        const errorMsg = err.message
        // 异常处理
        if (window.navigator.onLine === false) {
          err.message = '当前网络已断开，请检查网络连接！'
        } else if (err && err.response) {
          switch (err.response.status) {
            case 400:
              err.message = '请求错误(400)'
              break
            case 401:
              err.message = '未授权，请重新登录(401)'
              break
            case 403:
              err.message = '拒绝访问(403)'
              break
            case 404:
              err.message = '请求出错(404)'
              break
            case 408:
              err.message = '请求超时(408)'
              break
            case 500:
              err.message = '服务器错误(500)'
              break
            case 501:
              err.message = '服务未实现(501)'
              break
            case 502:
              err.message = '网络错误(502)'
              break
            case 503:
              err.message = '服务不可用(503)'
              break
            case 504:
              err.message = '网络超时(504)'
              break
            case 505:
              err.message = 'HTTP版本不受支持(505)'
              break
            default:
              err.message = `连接出错(${err.response.status})!` // @todo -- 后台暂时没有统一返回
          }
        } else if (errorMsg) {
          err.message =
            errorMsg === 'Network Error'
              ? '网络连接异常，请检查连接！'
              : errorMsg
        } else {
          err.message = '连接服务器失败!'
        }
        if (showErrorMsg) {
          if (err.message) {
            Notify({
              type: 'danger',
              message: err.message
            })
          } // Message.error(err.message)
          else {
            Notify({
              type: 'danger',
              message: JSON.stringify(err)
            })
          } //Message.error(JSON.stringify(err))
        }
        console.error(`${params.url.split('/').pop()}=`, err)
        reject(err)
      })
  })
}
